/**
 * Captialize string
 * @param {string} string String to capitalize
 * @returns {string} Same string as input with first letter capitalized
 */
export const capitalize = (string = "") => {
  return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
};

/**
 * Returns a string that will have plural suffix appended, provided amount of things being referred to is greater than 1.
 * @param {string} noun Things being referred to
 * @param {number} count Number of things being referred to
 * @param {string} options.suffix String to append to pluralized items if need be (e.g. 's' or 'es')
 * @returns {string} Appropriately pluralized string
 */
export const pluralize = (noun = "", count = 0, { suffix = "s" } = {}) => {
  const countInt = parseInt(count, 10);
  // if unable to coerce count as Number, return noun as is
  if (Number.isNaN(countInt)) return noun;
  return `${noun}${countInt !== 1 ? suffix : ""}`;
};

/**
 * Compare strings
 * @param {a} string String to compare
 * @param {b} string String to compare
 * @returns {boolean} True if strings are the same, false if not
 */
export const compareNodes = (a = "", b = "") => {
  return a.toLowerCase() === b.toLowerCase();
};

/**
 * Compare date strings
 * @param {a} string String in date format to compare
 * @param {b} string String in date format to compare
 * @returns {boolean} True if dates are the same, false if not
 */
export const compareDates = (a, b) => {
  return new Date(a).toLocaleDateString() === new Date(b).toLocaleDateString();
};

/**
 * Localize date
 * @param {date} Date Date object
 * @returns {string} string with localised format with hyphen separators
 */
export const localiseDateWithHyphen = date =>
  date
    .toLocaleDateString()
    .split("/")
    .reverse()
    .join("-");

/**
 * Checks if date is today
 * @param {date} Date Date object
 * @returns {boolean} True if the date is today, false is not
 */
export const dateIsToday = date => {
  return new Date().toDateString() === date.toDateString();
};

/**
 * Checks if date is yesterday
 * @param {date} Date Date object
 * @returns {boolean} True if the date is yesterday, false is not
 */
export const dateIsYesterday = date => {
  const d = new Date();
  d.setDate(d.getDate() - 1);
  return d.toDateString() === date.toDateString();
};

/**
 * Tansforms a string to lowercase
 * @param {string} String to convert
 * @returns {string} String converted
 */
export const lowercase = (elem = "") => elem.toLowerCase();

/**
 * Removes file extension from path
 * @param {string} Path to trim
 * @returns {string} Path without extension
 */
export const trimExtension = (path = "") => path.replace(/\.[^/.]+$/, "");

/**
 * Gets current date in ISO format
 * @returns {string} Date in ISO format
 */
export const getISODate = () =>
  new Date()
    .toISOString()
    .replace(/-/g, "")
    .replace(/:/g, "")
    .split(".")[0] + "Z";
