import React, { Fragment } from "react";
import { useHistory } from "react-router-dom";
import { ModalBody } from "components/Modal";
import FieldData from "components/AssetFieldData";

import { CopyToClipboard } from "react-copy-to-clipboard";
import { useToast } from "context/ToastContext";

import {
  IconEditM as IconPencil,
  IconLinkM as IconLink,
  IconImageM as IconImage,
  IconDocumentM as IconDocument,
  IconVideoM as IconVideo
} from "@softiron/icons-library";

import { ASSET_TYPES } from "helpers/constants";
import { compareNodes as stringsAreEqual } from "helpers/string";

import {
  Heading,
  Grid,
  GridCell,
  Button,
  MediaWrap,
  Paragraph
} from "@softiron/design-system";

const ModalAssetDetail = ({ asset, hideModal }) => {
  const {
    id,
    name,
    url,
    uploaded_on,
    type,
    thumbnail,
    sub_type,
    format,
    end_uses,
    tags,
    meta: { height, width }
  } = asset;
  const { toast } = useToast();
  const history = useHistory();

  const theme = { primary: "grey", secondary: "grey" };

  const getIcon = type => {
    switch (type.toLowerCase()) {
      case "doc":
        return <IconDocument className="square-L" theme={theme} />;
      case "video":
        return <IconVideo className="square-L" theme={theme} />;
      case "image":
      default:
        return <IconImage className="square-L" theme={theme} />;
    }
  };

  return (
    <Fragment>
      <ModalBody>
        <Grid align="middle">
          <GridCell width={{ default: "7-12" }} className="inset-horizontal-L">
            {!stringsAreEqual(type, ASSET_TYPES.VIDEO) ? (
              <MediaWrap
                ratio={
                  stringsAreEqual(type, ASSET_TYPES.IMAGE) ? "1x1" : "16x9"
                }
                fit="contain"
              >
                <img src={thumbnail} alt={name} />
              </MediaWrap>
            ) : (
              <iframe
                src={`https://player.vimeo.com/video/${url
                  .split("/")
                  .pop()}?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=179564`}
                width="100%"
                height="344"
                frameBorder="0"
                allow="autoplay; fullscreen"
                title={name}
                allowFullScreen
              ></iframe>
            )}
          </GridCell>
          <GridCell width={{ default: "5-12" }} className="inset-L">
            <Grid gutters="XS" align="middle" className="stack-M">
              <GridCell width={{ default: "auto" }}>{getIcon(type)}</GridCell>
              <GridCell width={{ default: "auto" }}>
                <Paragraph className="color-grey text-bold text-upper text-xxsmall text-tracked stack-none">
                  {type}
                </Paragraph>
              </GridCell>
            </Grid>

            <Heading level={3} className="stack-L word-break-all">
              {name}
            </Heading>
            <FieldData
              className="stack-M"
              field="Uploaded"
              data={new Date(uploaded_on).toLocaleDateString()}
            />
            <Grid className="stack-M">
              {height && width && (
                <GridCell width={{ default: "1-2" }}>
                  <FieldData field="Dimensions" data={`${width}x${height}`} />
                </GridCell>
              )}
              <GridCell width={{ default: "1-2" }}>
                <FieldData field="Format" data={format} />
              </GridCell>
            </Grid>
            <Grid className="stack-M">
              <GridCell width={{ default: "1-2" }}>
                <FieldData field="Media" data={type} />
              </GridCell>
              <GridCell width={{ default: "1-2" }}>
                <FieldData field="Type" data={sub_type} />
              </GridCell>
            </Grid>
            <FieldData
              className="stack-M"
              field="End Use"
              data={end_uses.join(", ")}
            />
            <FieldData
              className="stack-L"
              field="Tags"
              data={tags.join(", ")}
            />
            <Grid>
              <GridCell width={{ default: "1-2" }}>
                <Button
                  inverse
                  color="grey"
                  onClick={() => {
                    hideModal();
                    history.push(`/assets/${id}/edit`);
                  }}
                >
                  Edit Details
                  <IconPencil />
                </Button>
              </GridCell>
              <GridCell width={{ default: "1-2" }}>
                <CopyToClipboard
                  text={url}
                  onCopy={() => toast.success("URL copied!")}
                >
                  <Button inverse>
                    Copy URL
                    <IconLink />
                  </Button>
                </CopyToClipboard>
              </GridCell>
            </Grid>
          </GridCell>
        </Grid>
      </ModalBody>
    </Fragment>
  );
};

export default ModalAssetDetail;
