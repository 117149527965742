import React, { useState } from "react";

import FileUpload from "components/FileUpload";
import FileTypeBoxCover from "components/FileTypeBoxCover";

import { ASSET_TYPES } from "helpers/constants";
import { capitalize, pluralize } from "helpers/string";

import {
  IconArrowBackM as IconArrowBack,
  IconArrowForwardM as IconArrowForward,
  IconDocumentL as IconDocument,
  IconImageL as IconImage,
  IconVideoL as IconVideo,
  IconCloseM as IconClose
} from "@softiron/icons-library";

import {
  Button,
  Grid,
  GridCell,
  Heading,
  Section,
  Wrapper
} from "@softiron/design-system";

const TYPE_ICONS = {
  VIDEO: IconVideo,
  DOCUMENT: IconDocument,
  IMAGE: IconImage
};

const Upload = () => {
  const [selectedType, setSelectedType] = useState(null);
  const [files, setFiles] = useState([]);

  return (
    <Section className="inset-horizontal-XL stack-XL">
      <Wrapper className="stack-L inset-vertical-XL">
        <Heading level={2}>Upload your media</Heading>
      </Wrapper>
      <Wrapper>
        {selectedType && (
          <Grid>
            <GridCell className="position-relative" width={{ default: "full" }}>
              <Button
                color="grey"
                flat
                size="large"
                className="closeButton position-absolute"
                onClick={() => setSelectedType(null)}
              >
                <IconClose />
              </Button>
              {selectedType && (
                <FileUpload
                  type={selectedType}
                  icon={TYPE_ICONS[selectedType]}
                  setFiles={setFiles}
                />
              )}
            </GridCell>
          </Grid>
        )}
        {!selectedType && (
          <Grid guttersVertical className="text-center">
            {Object.values(ASSET_TYPES)
              .filter(t => t !== ASSET_TYPES.NO_TYPE)
              .map(type => (
                <GridCell
                  key={type}
                  width={{
                    default: selectedType === null ? "1-3" : "full"
                  }}
                  hidden={selectedType === type.toUpperCase()}
                >
                  <FileTypeBoxCover
                    icon={TYPE_ICONS[type]}
                    type={capitalize(type)}
                    selectFileTypeHandler={setSelectedType}
                  />
                </GridCell>
              ))}
          </Grid>
        )}
        {!!files.length && (
          <Grid className="mt-L" justify="spread">
            <GridCell width={{ default: "auto" }}>
              <Button color="grey" inverse to="/">
                <IconArrowBack /> Change media
              </Button>
            </GridCell>
            <GridCell width={{ default: "auto" }}>
              <Button
                to={{
                  pathname: `/assets/${files[0].id}/edit`,
                  state: { files }
                }}
              >
                {pluralize(capitalize(selectedType), files.length)} details{" "}
                <IconArrowForward />
              </Button>
            </GridCell>
          </Grid>
        )}
      </Wrapper>
    </Section>
  );
};

export default Upload;
