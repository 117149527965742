import React from "react";
import { useAuth } from "context/AuthContext";

import {
  Box,
  Button,
  ButtonSignIn,
  Grid,
  GridCell,
  Heading,
  LogoSoftIron,
  LogoGoogle,
  Paragraph,
  Wrapper
} from "@softiron/design-system";

import Tooltip from "components/Tooltip";

import "./index.css";

const Login = () => {
  const { login } = useAuth();

  return (
    <div className="flex flex--column height-full-vh login-screen">
      <Wrapper className="flex flex--middle flex--center flex--right-medium flex--grow ph-XL-medium">
        <Box className="bg-color-white border-color-white text-center max-width-400 pa-L pa-XL-medium flex--shrink">
          <LogoSoftIron width="130" className="mb-M" />
          <Heading level={1} display="h3">
            Soho
          </Heading>
          <Paragraph className="color-dark-grey">
            Our internal media library tool. Here we collate images, videos,
            icons and more.
          </Paragraph>
          <div>
            <ButtonSignIn className="mb-XL" onClick={login}>
              <LogoGoogle />
              Sign in with Google
            </ButtonSignIn>
          </div>
          <Tooltip
            content={
              <Paragraph className="mb-none">
                If you’re having trouble signing in, please make sure you're
                signing in with your softiron.com Google Account. If that still
                doesn’t work, please email{" "}
                <a className="link" href="mailto:network@softiron.com">
                  network@softiron.com
                </a>
              </Paragraph>
            }
            position="bottom"
            theme="navy"
            width="S"
          >
            {setIsPopoverOpen => (
              <Button
                flat
                size="large"
                onMouseOver={() => setIsPopoverOpen(true)}
                onFocus={() => setIsPopoverOpen(true)}
              >
                Trouble signing in?
              </Button>
            )}
          </Tooltip>
        </Box>
      </Wrapper>
      <Wrapper className="flex--fixed ph-XL-medium">
        <Grid gutters="M" justify="spread">
          <GridCell width={{ default: "auto" }}>
            <Paragraph className="color-grey">
              SoHo Marketing Tools v0.1.0
            </Paragraph>
          </GridCell>
          <GridCell width={{ default: "auto" }}>
            <Paragraph className="color-grey">
              Copyright © SoftIron Limited, {new Date().getFullYear()}. All
              rights reserved
            </Paragraph>
          </GridCell>
        </Grid>
      </Wrapper>
    </div>
  );
};

export default Login;
