// Screens
import Assets from "screens/Assets";
import AssetEdit from "screens/AssetEdit";
import Upload from "screens/Upload";
import TermsAndConditions from "screens/TermsAndConditions";

// Routes
export default [
  {
    path: ["/", "/assets/:id"],
    title: "Assets",
    component: Assets,
    exact: true,
    routes: [
      {
        path: "/upload",
        title: "New upload",
        component: Upload,
        exact: true
      },
      {
        path: "/assets/:id/edit",
        title: "Edit asset",
        component: AssetEdit,
        exact: true
      }
    ]
  },
  {
    path: "/terms",
    title: "Terms and Conditions",
    exact: true,
    component: TermsAndConditions
  }
];
