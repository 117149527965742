import React, { Fragment } from "react";
import PropTypes from "prop-types";

import GridCard from "components/GridCard";

import { Grid, GridCell } from "@softiron/design-system";

const AssetsGallery = ({ assets, squashed }) => {
  return (
    <Fragment>
      <Grid className="assets--gallery stack-L" guttersVertical>
        {assets.map(asset => (
          <GridCell
            key={asset.id}
            width={{ default: squashed ? "1-3" : "1-4" }}
          >
            <GridCard asset={asset} />
          </GridCell>
        ))}
      </Grid>
    </Fragment>
  );
};

AssetsGallery.propTypes = {
  assets: PropTypes.array.isRequired
};

export default AssetsGallery;
