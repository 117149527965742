// Pagination
export const PER_PAGE = 20;

// Button loading states
export const LOADING_STATES = {
  LOADING: "LOADING",
  SUCCESS: "SUCCESS",
  FAILED: "FAILED"
};

// Asset types
export const ASSET_TYPES = {
  VIDEO: "VIDEO",
  IMAGE: "IMAGE",
  DOCUMENT: "DOCUMENT",
  NO_TYPE: "Unknown"
};

export const DEFAULT_COVER = {
  DOCUMENT:
    "https://dam.softiron.com/images/f56d7901-2046-44c4-b37c-407a54d39da1.png"
};

// Assets list layout
export const ASSETS_VIEW = {
  LIST: "LIST",
  GRID: "GRID"
};

// Asset upload status
export const UPLOAD_STATUS = {
  READY: "READY",
  QUEUED: "QUEUED",
  PROCESSING: "PROCESSING",
  ERROR: "ERROR"
};

export const UPLOAD_STATUS_PERCENTAGE = {
  BASE: 50,
  STEP: 3,
  PROCESSING: 55,
  COMPLETE: 100
};

// File extensions
export const ASSET_FORMATS = {
  VIDEO: ".mpeg, .avi, .mov, .mp4, .gif",
  IMAGE: ".jpg, .jpeg, .png, .eps, .gif, .tiff",
  DOCUMENT: ".pdf, .doc, .rtf"
};

export const ACCEPTED_FORMATS = {
  VIDEO: [
    "video/mpeg",
    "video/avi",
    "video/quicktime",
    "video/mp4",
    "image/gif"
  ],
  IMAGE: [
    "image/jpg",
    "image/jpeg",
    "image/png",
    "image/eps",
    "image/gif",
    "image/tiff"
  ],
  DOCUMENT: ["application/pdf", "application/doc", "application/rtf"]
};
