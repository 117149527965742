import React, { Fragment } from "react";

import { Button, Grid, GridCell, TextInput } from "@softiron/design-system";

import { localiseDateWithHyphen } from "helpers/string";

import { useFilterContext } from "context/FilterContext";

const FilterDate = () => {
  const { filters, setFilters } = useFilterContext();
  const { date = "" } = filters;

  const handleChange = value => setFilters({ date: value });

  return (
    <Fragment>
      <TextInput
        type="date"
        name="date_filter"
        id="date_filter"
        min="2017-01-01"
        max={localiseDateWithHyphen(new Date())}
        value={date}
        onChange={({ target: { value } }) => handleChange(value)}
      />
      <Grid gutters="XS">
        <GridCell width={{ default: "1-2" }}>
          <Button
            color="grey"
            inverse
            size="small"
            onClick={() => {
              handleChange(localiseDateWithHyphen(new Date()));
            }}
          >
            Today
          </Button>
        </GridCell>
        <GridCell width={{ default: "1-2" }}>
          <Button
            color="grey"
            inverse
            size="small"
            onClick={() => handleChange("week")}
          >
            This week
          </Button>
        </GridCell>
      </Grid>
    </Fragment>
  );
};

export default FilterDate;
