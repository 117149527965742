import React from "react";

import { Checkbox } from "@softiron/design-system";

import { useFilterContext } from "context/FilterContext";

import { lowercase } from "helpers/string";

const FilterEndUses = () => {
  const { meta, filters, setFilters } = useFilterContext();
  const { end_uses = [] } = meta;
  const { end_uses: selected = [] } = filters;

  const handleChange = ({ target }) => {
    const { name, checked } = target;

    setFilters({
      end_uses: checked
        ? selected.concat(name.toLowerCase())
        : selected.filter(label => label !== name.toLowerCase())
    });
  };

  return end_uses.map(label => (
    <Checkbox
      key={label}
      id={label}
      label={label}
      name={label}
      checked={selected.map(lowercase).includes(label.toLowerCase())}
      onChange={handleChange}
    />
  ));
};

export default FilterEndUses;
