import React from "react";

import { Router, Route, Redirect, Switch } from "react-router-dom";

import history from "helpers/history";
import Login from "screens/Login";

const AppUnauthenticated = () => {
  return (
    <Router history={history}>
      <Switch>
        <Route path="/login" component={Login} exact />
        <Redirect
          to={{
            pathname: "/login"
          }}
        />
      </Switch>
    </Router>
  );
};

export default AppUnauthenticated;
