import React, { Fragment } from "react";

import { Heading, Section, Wrapper } from "@softiron/design-system";

const Terms = () => {
  return (
    <Fragment>
      <Section className="inset-vertical-L">
        <Wrapper>
          <Heading level={1} className="stack-XL">
            Terms and Conditions
          </Heading>
          <p>SOFTIRON END USER LICENSE AGREEMENT</p>
          <p>
            IMPORTANT – PLEASE CAREFULLY READ THE TERMS OF THIS END USER LICENSE
            AGREEMENT (THE “AGREEMENT”) FOR THE SOFTIRON SOFTWARE, WHICH
            INCLUDES COMPUTER SOFTWARE AND MAY INCLUDE ASSOCIATED MEDIA, PRINTED
            MATERIALS AND “ONLINE” OR ELECTRONIC DOCUMENTATION (COLLECTIVELY,
            THE “SOFTWARE”) WHICH IS PRE-INSTALLED ON THE ACCOMPANYING SOFTIRON
            PRODUCT(S) (THE “SOFTIRON PRODUCTS”). BY USING THE SOFTWARE AND/OR
            SOFTIRON PRODUCTS, (1) YOU ACKNOWLEDGE THAT YOU HAVE READ,
            UNDERSTAND, AND AGREE TO BE BOUND BY THE TERMS AND CONDITIONS OF
            THIS AGREEMENT, (2) YOU REPRESENT THAT YOU HAVE THE AUTHORITY TO
            ENTER INTO THIS AGREEMENT, ON BEHALF OF THE ENTITY OR PERSON
            IDENTIFIED ON THE PURCHASE ORDER IN RESPECT OF WHOM THE SOFTIRON
            PRODUCT WAS ORDERED (SUCH ENTITY OR PERSON, “END USER”), AND TO BIND
            END USER TO THE TERMS OF THIS AGREEMENT, AND (3) YOU AGREE THAT END
            USER IS ENTERING INTO THIS AGREEMENT WITH SOFTIRON LIMITED COMPANY
            REGISTERED IN ENGLAND (NO. 08172199) WITH ITS REGISTERED ADDRESS AT
            LEVEL 1, DEVONSHIRE HOUSE, ONE MAYFAIR PLACE, LONDON W1J 8AJ, UNITED
            KINGDOM (“SOFTIRON”). IF YOU DO NOT AGREE TO THE TERMS OF THIS
            AGREEMENT, OR IF YOU DO NOT HAVE AUTHORITY TO ACCEPT THIS AGREEMENT
            ON BEHALF OF THE END USER, DECLINE THESE TERMS AND CONDITIONS AND DO
            NOT USE THE SOFTWARE OR THE SOFTIRON PRODUCT.
          </p>
          <p>
            1. License Grant. Subject to the terms and conditions of this
            Agreement, SoftIron grants to End User a nontransferable,
            non-sublicensable, nonexclusive, royalty-free, fully paid,
            revocable, license to permit End User’s and its employees and
            consultants authorized by End User (“Authorized Users”) to execute
            and use the Software, in executable object code format only, solely
            for End User’s internal business purposes, and in accordance with
            this Agreement, any and all usage limitations specified by SoftIron,
            and any and all technical limitations implemented by SoftIron in the
            Software and/or SoftIron Products. End User understands and agrees
            that the Software is licensed solely for use on the SoftIron Product
            in which it is installed. End User shall be solely responsible and
            liable for the acts and omissions of its Authorized Users, and
            agrees that any non-compliance with the terms hereof by any
            Authorized User shall be deemed a breach of this Agreement by End
            User.
          </p>
          <p>
            2. Restrictions. The rights granted to End User in this Agreement
            are subject to the following restrictions: End User shall not, and
            shall not permit any third party to: (a) license, sell, rent, lease,
            transfer, assign, distribute, host, outsource, disclose or otherwise
            commercially exploit the Software or make the Software available to
            any third party other than as expressly permitted by this Agreement;
            (b) alter, modify, translate, adapt in any way, or prepare any
            derivative work based upon the Software; (c) decompile, reverse
            engineer, disassemble or otherwise attempt to reconstruct or
            discover the source code, underlying ideas or algorithms of any
            components of the Software; (d) access the Software in order to
            build a similar or competitive product or service; (e) except as
            expressly stated herein, copy, reproduce, distribute, republish,
            download, display, post or transmit in any form or by any means the
            Software (or any part thereof), including electronic, mechanical,
            photocopying, recording or other means. Any future release, update,
            or other addition to functionality of the Software shall be subject
            to the terms of this Agreement, unless SoftIron states otherwise in
            a signed writing by its authorized representative. End User shall
            preserve all copyright and other proprietary rights notices in the
            Software and all copies thereof. End User agrees to comply with all
            applicable local, state, national, and international laws, rules and
            regulations applicable to End User’s use of the Software.
          </p>
          <p>
            3. Support. Subject to End User’s compliance with the terms and
            conditions of this Agreement (including payment of applicable fees),
            SoftIron will use commercially reasonable efforts to provide End
            User remote technical and operational support for the Software
            (“Support”) during SoftIron’s normal business hours and in
            accordance with SoftIron’s then current Support terms and
            conditions. SoftIron, in its discretion, may make available Software
            updates and/or upgrades for End User to download from SoftIron’s
            website located at www.softiron.com (such updates and upgrades are
            included in the defined term “Software”).
          </p>
          <p>
            4. Third Party Software. The Software contains third-party software
            and is subject to terms and conditions imposed by the licensors of
            such third-party software available on request from SoftIron (“Third
            Party Software”). End User agrees to comply with terms and
            conditions contained in all such third-party software licenses with
            respect to the applicable Third Party Software.
          </p>
          <p>
            5. Indemnification. End User will defend, indemnify and hold
            SoftIron and its affiliates harmless from and against any and all
            claims, fines, penalties, costs, damages, expenses (including
            reasonable attorney’s fees), liabilities, and losses, including
            without limitation product liability claims, directly or indirectly
            arising from or related to: (a) End User’s breach of this Agreement,
            any license applicable to the Third Party Software, or any
            applicable law, rule or regulation; or (b) End User’s use of the
            Software.
          </p>
          <p>
            6. Ownership. All right, title, and interest, including all
            intellectual property rights, in and to the Software (including any
            and all copies thereof) shall be owned and retained by SoftIron or
            its suppliers. Any rights not expressly granted by SoftIron in this
            Agreement are reserved. End User acknowledges that it acquires no
            ownership interest in the Software. No implied licenses are granted
            by SoftIron.
          </p>
          <p>
            7. Disclaimer of Warranties. END USER AGREES THAT SOFTIRON AND ITS
            LICENSORS PROVIDE THE SOFTWARE ON AN “AS IS” AND “WHERE-AS” BASIS.
            SOFTIRON MAKES NO REPRESENTATIONS, WARRANTIES OR GUARANTEES
            WHATSOEVER WITH RESPECT TO THE SOFTWARE OR ANY THIRD PARTY SOFTWARE,
            WHETHER EXPRESS, IMPLIED, STATUTORY OR OTHERWISE, AND SOFTIRON AND
            ITS LICENSORS EXPRESSLY DISCLAIM ALL OTHER WARRANTIES, INCLUDING BUT
            NOT LIMITED TO THE IMPLIED WARRANTIES OF NON-INFRINGEMENT OF THIRD
            PARTY RIGHTS, MERCHANTABILITY AND FITNESS FOR A PARTICULAR PURPOSE.
            SOFTIRON DOES NOT WARRANT THAT USE OF THE SOFTWARE WILL MEET END
            USER’S REQUIREMENTS, BE UNINTERRUPTED OR ERROR-FREE, THAT DEFECTS
            WILL BE CORRECTED, OR THAT THE SOFTWARE IS FREE OF VIRUSES OR OTHER
            HARMFUL COMPONENTS.
          </p>
          <p>
            8. Limitation of Liability 9.1 Exclusion of Damages. TO THE MAXIMUM
            EXTENT PERMITTED BY APPLICABLE LAW, NEITHER SOFTIRON NOR ITS
            SUPPLIERS SHALL BE RESPONSIBLE OR LIABLE WITH RESPECT TO ANY SUBJECT
            MATTER OF THIS AGREEMENT OR TERMS OR CONDITIONS RELATED THERETO
            UNDER ANY CONTRACT, TORT (INCLUDING NEGLIGENCE), STRICT LIABILITY OR
            OTHER THEORY (A) FOR LOSS OR INACCURACY OF DATA OR COST OF
            PROCUREMENT OF SUBSTITUTE GOODS, SERVICES OR TECHNOLOGY, OR (B) FOR
            ANY INDIRECT, INCIDENTAL OR CONSEQUENTIAL DAMAGES, OR FOR ANY LOSS
            OF REVENUES OR LOSS OF PROFITS. 9.2 Limitation on damages. TO THE
            MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, SOFTIRON’S AGGREGATE
            CUMULATIVE LIABILITY HEREUNDER SHALL NOT EXCEED (1) THE AMOUNT PAID
            TO SOFTIRON FOR THE SPECIFIC SOFTWARE THAT GAVE RISE TO THE
            LIABILITY DURING THE SIX (6) MONTH PERIOD PRIOR TO THE EVENT GIVING
            RISE TO THE CLAIM OR LIABILITY, OR (2) FIVE HUNDRED POUNDS (GBP
            500.00), IF END USER HAS PAID NO FEES TO SOFTIRON HEREUNDER.
            SOFTIRON’S AFFILIATES AND SUPPLIERS SHALL HAVE NO LIABILITY ARISING
            FROM OR RELATED TO THIS AGREEMENT. 9.3 Allocation of Risk. The
            warranty disclaimer and limitation of liability set forth above are
            fundamental elements of the basis of the agreement between SoftIron
            and End User. SoftIron would not be able to provide the Software on
            an economic basis without such limitations. The warranty disclaimer
            and limitation of liability inure to the benefit of SoftIron’s
            suppliers.
          </p>
          <p>
            9. Termination. SoftIron may terminate this Agreement immediately
            upon written notice to End User if End User materially breaches any
            of the terms hereof. Upon termination, the license granted hereunder
            shall terminate and End User shall immediately destroy any copies of
            the Software in its and Authorized Users’ possession or control. The
            following provisions of this Agreement shall survive any termination
            of this Agreement: Sections 2, 5, 6, 7, 8, 9, 10 and 11.
          </p>
          <p>
            10. Export. End User acknowledges that the laws and regulations of
            the United States and foreign jurisdictions may restrict the export
            and re-export of certain commodities and technical data of United
            States origin, including the Software. End User agrees that it will
            not export or re-export any Software without the appropriate United
            States or foreign government licenses. End User will indemnify and
            hold SoftIron harmless from any and all claims, losses, liabilities,
            damages, fines, penalties, costs and expenses (including attorney’s
            fees) arising from or relating to any breach by End User of its
            obligations under this Section.
          </p>
          <p>
            11. Miscellaneous. This Agreement will be governed by and construed
            and enforced in all respects in accordance with the laws of England
            and Wales, without reference to its choice of law rules. SoftIron
            may seek injunctive or other equitable relief in any court of
            competent jurisdiction to protect any actual or threatened
            misappropriation or infringement of its intellectual property rights
            or those of its licensors, and End User hereby submits to the
            exclusive jurisdiction of such courts and waives any objection
            thereto. End User agrees that any breach of the license restrictions
            or other infringement or misappropriation of the intellectual
            property rights of SoftIron or its licensors will result in
            immediate and irreparable damage to SoftIron for which there is no
            adequate remedy at law. The United Nations Convention on Contracts
            for the International Sale of Goods in its entirety is expressly
            excluded from this Agreement, including, without limitation,
            application to the Software provided hereunder. Neither the rights
            nor the obligations arising under this Agreement are assignable by
            End User, and any such attempted assignment or transfer shall be
            void and without effect. This Agreement shall not be interpreted or
            construed to confer any rights or remedies on any third parties and
            no one other than a party to this Agreement, their successors and
            permitted assignees, shall have any right to enforce any of its
            terms. If any provision of this Agreement is unenforceable, such
            provision will be changed and interpreted to accomplish the
            objectives of such provision to the greatest extent possible under
            applicable law and the remaining provisions will continue in full
            force and effect. Any notice to End User may be provided by email.
            This Agreement constitutes the entire agreement between the parties
            pertaining to the subject matter hereof, and any and all written or
            oral agreements previously existing between the parties with respect
            to the subject matter hereof are expressly canceled. Except as
            otherwise expressly provided in this Agreement, no amendment or
            modification to this Agreement will be binding unless in writing and
            signed by SoftIron.
          </p>
          <p>
            12. Questions or Additional Information. If End User has questions
            regarding this Agreement, please send an e-mail to
            support@softiron.com.
          </p>
        </Wrapper>
      </Section>
    </Fragment>
  );
};

export default Terms;
