import React from "react";
import classnames from "classnames";

import "./index.css";

import { Button } from "@softiron/design-system";
import {
  IconChevronLeftS as IconChevronLeft,
  IconChevronRightS as IconChevronRight
} from "@softiron/icons-library";

const Pagination = ({ pages, paginate, page }) => {
  return pages > 1 ? (
    <ul className="pagination flex flex--center">
      <li hidden={page === 1}>
        <Button flat color="grey" onClick={() => paginate(page - 1)}>
          <IconChevronLeft />
        </Button>
      </li>
      {new Array(pages)
        .fill()
        .map((e, i) => {
          const currIndex = i + 1;
          return currIndex !== pages ? (
            <li key={i} className={classnames({ active: page === currIndex })}>
              <Button
                flat
                color="grey"
                onClick={() => paginate(currIndex)}
              >{`${currIndex}`}</Button>
            </li>
          ) : null;
        })
        .slice(
          pages - page > 1
            ? page < 2
              ? page - 1
              : page - 2
            : pages === page
            ? page - 4
            : page - 3,
          page + 2
        )}
      {page < pages - 1 && (
        <li className={classnames({ active: page === pages })}>
          <Button flat color="grey" onClick={() => paginate(pages)}>
            ...
          </Button>
        </li>
      )}
      <li className={classnames({ active: page === pages })}>
        <Button
          flat
          color="grey"
          onClick={() => paginate(pages)}
        >{`${pages}`}</Button>
      </li>
      <li hidden={page === pages}>
        <Button flat color="grey" onClick={() => paginate(page + 1)}>
          <IconChevronRight />
        </Button>
      </li>
    </ul>
  ) : null;
};

export default Pagination;
