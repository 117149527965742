import React from "react";

const FieldData = ({ field, data, className }) => {
  return (
    <div className={className}>
      <div className="color-grey text-upper text-bold text-tracked text-xxsmall stack-XS">
        {field}
      </div>
      <div className="color-dark-grey text-medium text-capital">
        {data ? data : "\u00A0"}
      </div>
    </div>
  );
};

export default FieldData;
