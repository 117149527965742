import {
  compareDates as datesAreEqual,
  compareNodes as stringsAreEqual,
  lowercase
} from "helpers/string";

import { ASSET_TYPES } from "helpers/constants";

export const filterByTags = filter => ({ tags = [] }) =>
  !filter.length || tags.some(t => filter.includes(t));

export const filterByEndUses = filter => ({ end_uses = [] }) =>
  !filter.length || end_uses.some(u => filter.includes(u.toLowerCase()));

export const filterBySearch = filter => ({ name = "" }) =>
  !filter || name.toLowerCase().includes(filter.toLowerCase());

export const filterByDate = filter => ({ uploaded_on = "" }) => {
  switch (filter) {
    case "":
      return true;
    case "week":
      const uploadedDate = new Date(uploaded_on);
      const toDate = new Date();
      let fromDate = new Date();
      fromDate.setDate(toDate.getDate() - 7);
      return fromDate <= uploadedDate && uploadedDate <= toDate;
    default:
      return datesAreEqual(uploaded_on, filter);
  }
};

export const filterBySubTypes = filter => ({ type, sub_type }) =>
  !filter.length ||
  filter.map(lowercase).includes(lowercase(`${type}:${sub_type}`)) ||
  stringsAreEqual(sub_type, ASSET_TYPES.NO_TYPE);

export const filterByType = filter => ({ type }) =>
  !filter || stringsAreEqual(type, filter);
