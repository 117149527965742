/**
 * Function to recursively reduce array of routes with nested routes into single array of route objects.
 * @param {Array} acc Accumulated array of routes
 * @param {Object} cur Current value when iterating over routes
 * @returns {Array} Flat array of `route` objects ({ path, title, component, exact })
 */
export const flattenRoutes = (acc, cur) => {
  let newRoute = {};
  Object.keys(cur).forEach(key => {
    if (key !== "routes") newRoute[key] = cur[key];
  });
  acc.push(newRoute);
  if (Array.isArray(cur.routes)) {
    return cur.routes.reduce(flattenRoutes, acc);
  }
  return acc;
};
