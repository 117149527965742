import React from "react";
import "./index.css";

import classNames from "classnames";

const TabItem = props => {
  const { children, className, onClick } = props;

  const btnClass = classNames(
    "tab--item",
    "flex",
    "flex--middle",
    "flex--spread",
    "inset-stretch-M",
    "color-grey",
    "text-medium",
    className
  );

  return (
    <button className={btnClass} onClick={onClick}>
      {children}
    </button>
  );
};

export default TabItem;
