import React from "react";
import classnames from "classnames";

import { Heading, Grid, GridCell, Button } from "@softiron/design-system";

import "./index.css";

import AssetFilterSection from "components/AssetsFilter/AssetFilterSection";

import FilterTypes from "components/FilterTypes";
import FilterEndUses from "components/FilterEndUses";
import FilterSearch from "components/FilterSearch";
import FilterDate from "components/FilterDate";
import FilterTags from "components/FilterTags";

import { useFilterContext } from "context/FilterContext";

const AssetsFilter = ({ enabled }) => {
  const { type, reset } = useFilterContext();

  return (
    <div
      className={classnames(
        "assets--filter bg-color-lighter-grey inset-stretch-M",
        { enabled }
      )}
    >
      <Grid justify="spread" align="middle" className="stack-L">
        <GridCell width={{ default: "auto" }}>
          <Heading level={3} className="text-normal stack-none">
            Filter {type ? `${type.toLowerCase()}s` : "library"}
          </Heading>
        </GridCell>
        <GridCell width={{ default: "auto" }} className="text-right">
          <Button flat size="small" onClick={reset}>
            Reset
          </Button>
        </GridCell>
      </Grid>
      <FilterTypes />
      <AssetFilterSection title="Date Uploaded">
        <FilterDate />
      </AssetFilterSection>
      <AssetFilterSection title="Search">
        <FilterSearch />
      </AssetFilterSection>
      <AssetFilterSection title="Usage">
        <FilterEndUses />
      </AssetFilterSection>
      <AssetFilterSection title="Contributors"></AssetFilterSection>
      <AssetFilterSection title="Tags">
        <FilterTags />
      </AssetFilterSection>
    </div>
  );
};

export default AssetsFilter;
