import React, { useState, useEffect, useContext, createContext } from "react";
import axios from "axios";

import { useToast } from "context/ToastContext";

import { dateSort } from "helpers/utils";

// Context
const AssetsContext = createContext();

// Provider
const AssetsProvider = ({ children }) => {
  const { toast } = useToast();

  const [loading, setLoading] = useState(false);
  const [lastUpdated, setLastUpdated] = useState(new Date());
  const [assetsList, setAssetsList] = useState([]);

  const refetch = () => setLastUpdated(new Date());

  // Fetches assets data
  useEffect(() => {
    setLoading(true);
    axios
      .get("/assets/?limit=500")
      .then(({ data }) => setAssetsList(data.sort(dateSort)))
      .catch(err => toast.error(`${err.message}: Could not load assets`))
      .finally(() => setLoading(false));
  }, [lastUpdated, toast]);

  return (
    <AssetsContext.Provider
      value={{
        loading,
        setLoading,
        assetsList,
        setAssetsList,
        refetch
      }}
    >
      {children}
    </AssetsContext.Provider>
  );
};

// Hook
const useAssetsContext = () => {
  const context = useContext(AssetsContext);
  if (context === undefined)
    throw new Error("useAssetsContext must be used within a AssetsProvider");
  return context;
};

export { AssetsProvider, useAssetsContext };
