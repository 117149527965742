import React from "react";
import PropTypes from "prop-types";

import "./index.css";

const Toast = props => {
  const { type, children, fixed, className, onDismiss } = props;

  const classNames = [
    "alert",
    "alert-" + type,
    fixed ? "fixed--alert" : "",
    className
  ];

  return (
    <div onClick={onDismiss} className={classNames.join(" ")}>
      {children}
    </div>
  );
};

Toast.propTypes = {
  type: PropTypes.oneOf(["error", "success", "info", "warning"])
};

export default Toast;
