import React from "react";

import { TextInput } from "@softiron/design-system";

import { useFilterContext } from "context/FilterContext";

const FilterSearch = props => {
  const { filters, setFilters } = useFilterContext();
  const { search = "" } = filters;

  return (
    <TextInput
      type="text"
      name="search_term"
      id="search_term"
      value={search}
      placeholder="Search the library"
      onChange={({ target }) => {
        const { value } = target;
        setFilters({ search: value });
      }}
    />
  );
};

export default FilterSearch;
