import React from "react";
import classnames from "classnames";
import "./index.css";

const TagItem = ({ value, className, onClick, active }) => {
  return (
    <span
      onClick={() => onClick(value)}
      className={classnames("tag__item inset-S", className, {
        "is-active": active
      })}
    >
      {value}
    </span>
  );
};

export default TagItem;
