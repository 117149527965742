import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import AriaModal from "react-aria-modal";

import { IconCloseM as IconClose } from "@softiron/icons-library";

const Modal = ({
  title,
  id = "modal",
  className,
  children,
  hideModal,
  preventClose,
  ...props
}) => {
  return (
    <AriaModal
      titleText={title}
      initialFocus={`#${id}`}
      getApplicationNode={() => document.getElementById("root")} // App #root element
      verticallyCenter={true}
      underlayStyle={{ background: "rgba(0, 0, 0, 0.4)" }}
      underlayClickExits={false}
      onExit={!preventClose ? hideModal : null}
      {...props}
    >
      <div
        id={id}
        className={classNames("modal", className)}
        tabIndex="0" // eslint-disable-line
      >
        {!preventClose ? (
          <button className="modal__close" onClick={hideModal}>
            <span className="modal__close-icon">
              <IconClose />
            </span>
            <span className="modal__close-text">Close Modal</span>
          </button>
        ) : null}
        {children}
      </div>
    </AriaModal>
  );
};

Modal.propTypes = {
  title: PropTypes.string.isRequired,
  id: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
    PropTypes.string
  ]),
  hideModal: PropTypes.func.isRequired,
  preventClose: PropTypes.bool
};

export default Modal;
