import React, { useState } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import Popover, { ArrowContainer } from "react-tiny-popover";

const Tooltip = ({
  align,
  content,
  children,
  className,
  hasCloseButton,
  inModal,
  justify,
  position,
  theme,
  width,
  ...props
}) => {
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);

  const classes = classNames(
    "tooltip",
    {
      [`tooltip--${justify}`]: justify,
      [`tooltip--width-${width}`]: width
    },
    className
  );

  const contentClasses = classNames("tooltip__content", {
    [`tooltip__content--${theme}`]: theme
  });

  const themes = {
    navy: "#0c314f",
    white: "#fff"
  };

  return (
    <Popover
      align={align}
      containerClassName={classes}
      containerStyle={{ overflow: "initial" }}
      isOpen={isPopoverOpen}
      onClickOutside={() => setIsPopoverOpen(false)}
      position={position}
      windowBorderPadding={20}
      content={({ position, targetRect, popoverRect }) => (
        <ArrowContainer
          arrowColor={themes[theme]}
          arrowSize={10}
          arrowStyle={{ margin: "2px auto" }} // compensate for the border radius on the popover
          position={position}
          targetRect={targetRect}
          popoverRect={popoverRect}
        >
          <div className={contentClasses}>
            {typeof content === "function"
              ? content(setIsPopoverOpen)
              : content}
            {hasCloseButton && (
              <button onClick={() => setIsPopoverOpen(false)}>Close</button>
            )}
          </div>
        </ArrowContainer>
      )}
      {...(inModal && {
        contentLocation: ({ nudgedLeft, nudgedTop }) => ({
          top: nudgedTop - parseInt(document.documentElement.style.top || 0),
          left: nudgedLeft
        })
      })}
      {...props}
    >
      {typeof children === "function" ? children(setIsPopoverOpen) : children}
    </Popover>
  );
};

Tooltip.propTypes = {
  align: PropTypes.oneOf(["start", "center", "end"]),
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
    PropTypes.func
  ]).isRequired,
  className: PropTypes.string,
  inModal: PropTypes.bool,
  justify: PropTypes.oneOf(["left", "center", "right"]),
  position: PropTypes.oneOf(["top", "bottom", "left", "right"]),
  theme: PropTypes.oneOf(["navy", "white"]),
  width: PropTypes.oneOf(["S", "M", "L"])
};

Tooltip.defaultProps = {
  align: "center",
  justify: "center",
  position: "top",
  theme: "navy",
  width: "M"
};

export default Tooltip;
