import React, {
  createContext,
  useContext,
  useReducer,
  useMemo,
  useCallback
} from "react";
import Toast from "components/Toast";
import ToastsContainer from "components/ToastsContainer";

const TOAST_TYPES = {
  SUCCESS: "success",
  INFO: "info",
  WARNING: "warning",
  ERROR: "error"
};

// Context
const ToastContext = createContext({
  toasts: [],
  addToast: () => {},
  remove: () => {}
});

// Tasts container
const ToastBar = () => {
  const { toasts, remove } = useContext(ToastContext);
  return (
    <ToastsContainer>
      {toasts.map(({ id, message, type }) => (
        <Toast onDismiss={() => remove(id)} key={id} type={type}>
          {message}
        </Toast>
      ))}
    </ToastsContainer>
  );
};

// Hook
const useToast = () => {
  const { toast, remove } = useContext(ToastContext);
  return { toast, remove };
};

const toastReducer = (toasts, action) => {
  switch (action.type) {
    case "remove":
      return toasts.filter(toast => toast.id !== action.id);
    case "add":
      return [...toasts, action.toast];
    default:
      throw new Error("Unexpected action in toast reducer");
  }
};

// Provider
const ToastProvider = ({ children }) => {
  const [toasts, dispatch] = useReducer(toastReducer, []);

  const add = useCallback((message, type, delay) => {
    const id = Math.random()
      .toString(36)
      .substring(2, 15);

    const toast = { message, type, id };
    dispatch({ type: "add", toast });
    setTimeout(() => remove(id), delay);
    return id;
  }, []);

  const remove = id => {
    dispatch({ type: "remove", id });
  };

  const toast = useMemo(
    () => ({
      success: (message, delay = 3000) =>
        add(message, TOAST_TYPES.SUCCESS, delay),
      info: (message, delay = 3000) => add(message, TOAST_TYPES.INFO, delay),
      error: (message, delay = 3000) => add(message, TOAST_TYPES.ERROR, delay),
      warning: (message, delay = 3000) =>
        add(message, TOAST_TYPES.WARNING, delay)
    }),
    [add]
  );

  const contextValue = useMemo(() => ({ toast, remove, toasts }), [
    toasts,
    toast
  ]);

  return (
    <ToastContext.Provider value={contextValue}>
      {children}
    </ToastContext.Provider>
  );
};

export { useToast, ToastBar, ToastProvider };
