import React, { Suspense, useState, useContext, useCallback } from "react";
import PropTypes from "prop-types";

import { Loader } from "@softiron/design-system";

import { Modal } from "components/Modal";

const ModalContext = React.createContext({
  component: null,
  props: {}
});

const ModalProvider = ({ children }) => {
  const [component, setComponent] = useState(null);
  const [modalProps, setModalProps] = useState({});
  const { referrerModal, referrerProps, ...restModalProps } = modalProps;

  // pick props from component for use on Modal and cloned modal child component
  const { props: componentProps } = component || {};
  const { title, className, onOpen, onClose, onSubmit, preventClose } =
    componentProps || {};

  const showModal = useCallback(
    (component, props = {}) => {
      setComponent(component);
      setModalProps(props);
      if (onOpen && typeof onOpen === "function") onOpen();
    },
    [onOpen]
  );

  const hideModal = () => {
    setComponent(null);
    setModalProps({});
    if (onClose && typeof onClose === "function") onClose();
  };

  return (
    <ModalContext.Provider
      value={{ showModal, hideModal, referrerModal, referrerProps }}
    >
      {children}
      {component ? (
        <Modal
          hideModal={hideModal}
          title={title}
          className={className}
          preventClose={preventClose}
          {...restModalProps}
        >
          <Suspense fallback={<Loader loaded="LOADING" type="screen" />}>
            {React.cloneElement(component, {
              hideModal,
              onOpen,
              onClose,
              onSubmit,
              referrerModal,
              referrerProps
            })}
          </Suspense>
        </Modal>
      ) : null}
    </ModalContext.Provider>
  );
};

const useModalContext = () => {
  const context = useContext(ModalContext);
  if (context === undefined)
    throw new Error("useModalContext must be used within a ModalProvider");
  return context;
};

ModalProvider.propTypes = {
  children: PropTypes.node
};

export { ModalProvider, useModalContext };
