import React, { useState, Fragment } from "react";
import { useDropzone } from "react-dropzone";

import axios from "axios";
import classnames from "classnames";

import Evaporate from "evaporate";
import MD5 from "js-md5";
import { sha256 as SHA256 } from "js-sha256";

import "./index.css";

import { useToast } from "context/ToastContext";

import {
  ASSET_FORMATS,
  UPLOAD_STATUS,
  UPLOAD_STATUS_PERCENTAGE,
  ACCEPTED_FORMATS
} from "helpers/constants";
import { trimExtension, getISODate } from "helpers/string";

import {
  Box,
  Button,
  Heading,
  Grid,
  GridCell,
  Progress,
  Paragraph,
  Loader
} from "@softiron/design-system";

import { IconTickM as IconTick } from "@softiron/icons-library";

const FileUpload = ({ type, setFiles, icon: Icon }) => {
  const [uploading, setUploading] = useState([]);

  const { toast } = useToast();

  var config = {
    signerUrl: `${process.env.REACT_APP_API_HOST}aws/sign/`,
    aws_key: process.env.REACT_APP_AWS_KEY,
    bucket: process.env.REACT_APP_AWS_BUCKET,
    cloudfront: true,
    sendCanonicalRequestToSignerUrl: true,
    signHeaders: {
      Authorization: axios.defaults.headers.common["Authorization"]
    },
    computeContentMd5: true,
    cryptoMd5Method: data =>
      MD5.create()
        .update(data)
        .base64(),
    cryptoHexEncodedHash256: data =>
      SHA256.create()
        .update(data)
        .hex()
  };

  let files = [];

  function setFileProgress(data, progress, status) {
    const { name } = data;
    setUploading(uploading =>
      uploading.map(file => {
        return trimExtension(file.name) !== trimExtension(name) ||
          file.progress === 100
          ? file
          : { name, progress, status };
      })
    );
  }

  async function onDrop(acceptedFiles, rejectedFiles) {
    setUploading(uploading =>
      acceptedFiles.map(({ name }) => ({
        name,
        progress: 0,
        status: "Uploading"
      }))
    );

    let evaporate;

    try {
      // Creating the evaporate insance
      evaporate = await Evaporate.create(config);
    } catch (e) {
      console.error(e);
    }

    const uploaders = acceptedFiles.slice(0, 4).map(file => {
      const addConfig = {
        name: `scratch/${getISODate()}-${file.name}`,
        progress: p =>
          setFileProgress(file, UPLOAD_STATUS_PERCENTAGE.BASE * p, "Uploading"),
        file,
        contentType: file.type
      };

      return new Promise(async (resolve, reject) => {
        try {
          // Once the file is uploaded to S3, we get its key
          const s3_key = await evaporate.add(addConfig);
          // We use the S3 key of the uploaded file to trigger its process in
          // backend
          const { data } = await axios.post("/assets/process/", { s3_key });

          // The file progress is set to 20% when the file has been uploaded to S3
          setFileProgress(file, UPLOAD_STATUS_PERCENTAGE.BASE, "Processing");

          const { id } = data;
          let intervalsRan = 0;
          const processId = setInterval(async () => {
            const { data } = await axios.get(`/assets/${id}/`);
            const { status } = data;
            switch (status) {
              case UPLOAD_STATUS.READY:
                clearInterval(processId);
                files.push(data);
                setFileProgress(file, UPLOAD_STATUS_PERCENTAGE.COMPLETE);
                resolve("complete");
                break;
              case UPLOAD_STATUS.ERROR:
                clearInterval(processId);
                reject("error");
                break;
              case UPLOAD_STATUS.PROCESSING:
              default:
                // We set progress to 35% + 5% as long as the file is processing
                setFileProgress(
                  file,
                  Math.min(
                    UPLOAD_STATUS_PERCENTAGE.PROCESSING +
                      intervalsRan * UPLOAD_STATUS_PERCENTAGE.STEP,
                    95
                  ),
                  "Processing"
                );
                intervalsRan++;
            }
          }, 500);
        } catch (error) {
          toast.error(error);
        }
      });
    });

    axios.all(uploaders).then(() => {
      setFiles(files.sort((a, b) => a.id - b.id));
      toast.info("All files have been processed!");
    });
  }

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragReject
  } = useDropzone({ onDrop, accept: ACCEPTED_FORMATS[type].join(", ") });

  return (
    <Box
      {...getRootProps()}
      className={classnames(
        "uploaderInner",
        "flex",
        "flex--center",
        "bg-color-lighter-grey",
        "inset-horizontal-M",
        {
          isDragActive,
          isDragReject
        }
      )}
    >
      <input {...getInputProps()} />
      <Grid
        style={{ minHeight: "350px" }}
        justify="center"
        align="middle"
        className="text-center no-pointer inset-vertical-L width-full"
      >
        {!uploading.length && (
          <GridCell width={{ default: "full" }}>
            <Icon height={48} width={48} className="stack-M" />
            <Heading level={3} className="stack-L text-capital">
              {(type && type.toLowerCase()) || "Image"}
            </Heading>
            <Paragraph className="stack-S">
              Drag and drop your {type && type.toLowerCase()}/s here (4 max)
            </Paragraph>
            <Button
              size="small"
              color="grey"
              inverse
              className="stack-M inset-horizontal-L"
            >
              Or select a file to upload
            </Button>
            {type && (
              <Paragraph size="xxsmall" className="color-dark-grey">
                {ASSET_FORMATS[type]} accepted
              </Paragraph>
            )}
            {isDragReject && <Paragraph>Incorrect file format</Paragraph>}
          </GridCell>
        )}
        {!!uploading.length && (
          <GridCell width={{ default: "1-2" }}>
            {uploading.map((file, index) => {
              const isComplete = file.progress === 100;
              return (
                <Fragment key={index}>
                  <Progress
                    value={file.progress}
                    theme={isComplete ? "green" : "navy"}
                  />
                  <Grid justify="spread" className="inset-horizontal-S mt-XS">
                    <GridCell width={{ default: "auto" }}>
                      <Paragraph>
                        {!isComplete ? file.status : ""} "{file.name}"{" "}
                        {isComplete ? "Uploaded" : ""}
                      </Paragraph>
                    </GridCell>
                    <GridCell width={{ default: "auto" }}>
                      {isComplete ? (
                        <IconTick />
                      ) : (
                        <Loader isLoading overlayColor="tranparent" />
                      )}
                    </GridCell>
                  </Grid>
                </Fragment>
              );
            })}
          </GridCell>
        )}
      </Grid>
    </Box>
  );
};

export default FileUpload;
