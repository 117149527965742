import React from "react";
import ReactDOM from "react-dom";

import App from "App";

import { AuthProvider } from "context/AuthContext";
import { ToastProvider } from "context/ToastContext";

import * as serviceWorker from "serviceWorker";

ReactDOM.render(
  <AuthProvider>
    <ToastProvider>
      <App />
    </ToastProvider>
  </AuthProvider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
