import React from "react";
import PropTypes from "prop-types";

import { NavigationProgress } from "@softiron/design-system";

const UploadFilesProgress = ({ files, current }) => {
  const currentId = files.findIndex(
    ({ id }) => parseInt(id) === parseInt(current)
  );
  return (
    <NavigationProgress
      current={currentId}
      enabled={files.length}
      locked={null}
      routes={files.map(({ name, id }) => ({
        title: name,
        path: { pathname: `/assets/${id}/edit`, state: { files } }
      }))}
    />
  );
};

UploadFilesProgress.propTypes = {
  files: PropTypes.array.isRequired,
  current: PropTypes.string.isRequired
};

UploadFilesProgress.defaultProps = {
  files: [],
  current: 0
};

export default UploadFilesProgress;
