import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

const ModalBody = ({ align, children, className, ...props }) => {
  const classes = classNames(
    "modal__body",
    { [`modal__body--${align}`]: align },
    className
  );

  return (
    <div className={classes} {...props}>
      {children}
    </div>
  );
};

ModalBody.propTypes = {
  align: PropTypes.oneOf(["top", "middle", "bottom"]),
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
    PropTypes.string
  ]),
  className: PropTypes.string
};

ModalBody.defaultProps = {
  align: "top"
};

export default ModalBody;
