import React from "react";
import { Router, Switch, Route, NavLink } from "react-router-dom";
import { Site, SiteMain, SiteHeader, Button } from "@softiron/design-system";
import { IconLogoutM as IconLogout } from "@softiron/icons-library";

import NotFound from "screens/NotFound";

import history from "helpers/history";
import { flattenRoutes } from "helpers/routes";
import routes from "routes";

import Footer from "components/Footer";

import { useAuth } from "./context/AuthContext";
import { AssetsProvider } from "./context/AssetsContext";
import { FilterProvider } from "./context/FilterContext";
import { ModalProvider } from "./context/ModalContext";

const AppAuthenticated = props => {
  const { logout } = useAuth();

  return (
    <Router history={history}>
      <ModalProvider>
        <AssetsProvider>
          <FilterProvider>
            <Site>
              <SiteHeader title="Soho Marketing Tools">
                <nav>
                  <ul className="grid grid--middle grid--gutters-vertical text-bold">
                    <li className="grid__cell grid__cell--auto">
                      <NavLink
                        to="/"
                        exact
                        className="link color-white text-xsmall"
                        activeClassName="color-green"
                      >
                        Library
                      </NavLink>
                    </li>
                    <li className="grid__cell grid__cell--auto">
                      <Button
                        flat
                        color="white"
                        className="color-white text-xsmall"
                        onClick={() =>
                          logout({
                            returnTo: window.location.origin
                          })
                        }
                      >
                        Logout
                        <IconLogout />
                      </Button>
                    </li>
                  </ul>
                </nav>
              </SiteHeader>
              <SiteMain>
                <Switch>
                  {routes
                    .reduce(flattenRoutes, [])
                    .map(({ path, exact, component }, i) => (
                      <Route
                        key={i}
                        path={path}
                        exact={exact}
                        component={component}
                        routes={routes}
                      />
                    ))}
                  <Route component={NotFound} />
                </Switch>
              </SiteMain>
              <Footer appVersion={"1.0.0"} />
            </Site>
          </FilterProvider>
        </AssetsProvider>
      </ModalProvider>
    </Router>
  );
};

export default AppAuthenticated;
