import React, { Fragment, useEffect } from "react";
import axios from "axios";

import { useAuth } from "context/AuthContext";
import { ToastBar } from "context/ToastContext";

import { Loader } from "@softiron/design-system";

import AppAuthenticated from "./AppAuthenticated";
import AppUnauthenticated from "./AppUnauthenticated";

axios.defaults.baseURL = process.env.REACT_APP_API_HOST;

const App = () => {
  const { loading, isAuthenticated, token } = useAuth();

  useEffect(() => {
    axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  }, [token]);

  if (loading) return <Loader loaded="LOADING" type="screen" />;

  return (
    <Fragment>
      <ToastBar />
      {isAuthenticated ? <AppAuthenticated /> : <AppUnauthenticated />}
    </Fragment>
  );
};

export default App;
