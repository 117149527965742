import React from "react";

const AssetFilterSection = ({ title, children }) => {
  return (
    <div className="asset--filter--section width-100">
      {title && (
        <div className="section--header text-bold stack-M">{title}</div>
      )}
      {children}
    </div>
  );
};

export default AssetFilterSection;
