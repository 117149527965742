import React from "react";
import PropTypes from "prop-types";

import { Heading, Button, Box, Paragraph } from "@softiron/design-system";

import { ASSET_FORMATS } from "helpers/constants";

const FileTypeBoxCover = ({ type, icon: Icon, selectFileTypeHandler }) => (
  <Box
    cover
    className="inset-vertical-L bg-color-white border-color-light-navy"
  >
    <Icon className="stack-M" width={48} height={48} />
    <Heading level={3}>
      <Button
        cover
        href="#"
        className="stack-none color-navy"
        onClick={() => selectFileTypeHandler(type.toUpperCase())}
      >
        {type}
      </Button>
    </Heading>
    <Paragraph
      size="xxsmall"
      className="color-dark-grey text-normal stack-none"
    >
      {ASSET_FORMATS[type.toUpperCase()]} accepted
    </Paragraph>
  </Box>
);

FileTypeBoxCover.propTypes = {
  type: PropTypes.string.isRequired,
  selectFileTypeHandler: PropTypes.func.isRequired
};

export default FileTypeBoxCover;
