import React from "react";
import PropTypes from "prop-types";
import { useHistory, useLocation } from "react-router-dom";
import "./index.css";

import { Button, MediaWrap, Table } from "@softiron/design-system";

import AssetsTableOptions from "components/AssetsTableOptions";

import {
  localiseDateWithHyphen,
  dateIsYesterday,
  dateIsToday
} from "helpers/string";

const AssetsTable = ({ assets }) => {
  const history = useHistory();
  const { search } = useLocation();

  const openDetailsModal = id =>
    history.push({
      pathname: `/assets/${id}`,
      search
    });

  return (
    <Table className="assets--table--view">
      <thead>
        <tr>
          <th>Preview</th>
          <th>Name</th>
          <th>Uploaded</th>
          <th>Sub Type</th>
          <th>End use</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        {assets.map(asset => {
          const {
            name,
            id,
            uploaded_on,
            end_uses,
            sub_type,
            thumbnail
          } = asset;
          return (
            <tr key={id}>
              <td>
                <MediaWrap fit="contain" className="bg-color-lighter-grey">
                  <img
                    src={thumbnail}
                    alt={name}
                    onClick={() => openDetailsModal(id)}
                  />
                </MediaWrap>
              </td>
              <td>
                <Button flat color="navy" onClick={() => openDetailsModal(id)}>
                  {name}
                </Button>
              </td>
              <td>
                {dateIsToday(new Date(uploaded_on))
                  ? "Today"
                  : dateIsYesterday(new Date(uploaded_on))
                  ? "Yesterday"
                  : localiseDateWithHyphen(new Date(uploaded_on))}
              </td>
              <td>{sub_type}</td>
              <td>{end_uses.join(", ")}</td>
              <td>
                <AssetsTableOptions asset={asset} />
              </td>
            </tr>
          );
        })}
      </tbody>
    </Table>
  );
};

AssetsTable.propTypes = {
  assets: PropTypes.array.isRequired
};

export default AssetsTable;
