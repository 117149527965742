import React, { useState, useEffect, useContext, createContext } from "react";
import { useHistory, useLocation } from "react-router-dom";
import axios from "axios";
import qs from "query-string";

import { useToast } from "context/ToastContext";

// Context
const FilterContext = createContext();

// Provider
const FilterProvider = ({ children }) => {
  const { toast } = useToast();
  const history = useHistory();
  const { search: locationSearch = {} } = useLocation();

  const [meta, setMeta] = useState({});

  const {
    sub_types = [],
    type = "",
    tags = [],
    end_uses = [],
    search = "",
    date = ""
  } = qs.parse(locationSearch, { arrayFormat: "comma" });

  // Fetches metadata for filtering
  useEffect(() => {
    axios
      .get("/meta/")
      .then(({ data }) => setMeta(data))
      .catch(err =>
        toast.error(
          `${err.message}: Could not load meta data (types, tags, end_uses)`
        )
      );
  }, [toast]);

  const reset = () =>
    setFilters({
      date: undefined,
      search: undefined,
      type: undefined,
      sub_types: [],
      end_uses: [],
      tags: []
    });

  const setFilters = data => {
    history.push({
      search: qs.stringify(
        {
          ...qs.parse(locationSearch, { arrayFormat: "comma" }),
          ...data
        },
        {
          arrayFormat: "comma",
          encode: false
        }
      )
    });
  };

  return (
    <FilterContext.Provider
      value={{
        filters: {
          tags: [].concat(tags),
          end_uses: [].concat(end_uses),
          sub_types: [].concat(sub_types),
          type,
          search,
          date
        },
        setFilters,
        meta,
        reset
      }}
    >
      {children}
    </FilterContext.Provider>
  );
};

// Hook
const useFilterContext = () => {
  const context = useContext(FilterContext);
  if (context === undefined)
    throw new Error("useFilterContext must be used within a FilterProvider");
  return context;
};

export { FilterProvider, useFilterContext };
