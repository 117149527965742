import React, { useState, useEffect } from "react";
import { useParams, useLocation, useHistory } from "react-router-dom";
import axios from "axios";

import { Loader } from "@softiron/design-system";

import AssetEditForm from "components/AssetEditForm";
import UploadFilesProgress from "components/UploadFilesProgress";

import { useToast } from "context/ToastContext";
import { LOADING_STATES } from "helpers/constants";

const AssetEdit = () => {
  const [loading, setLoading] = useState(null);
  const [file, setFile] = useState(null);

  const { toast } = useToast();
  const history = useHistory();
  const { state = {} } = useLocation();
  const { files = [] } = state;
  const { id } = useParams();

  useEffect(() => {
    setLoading(LOADING_STATES.LOADING);
    axios
      .get(`/assets/${id}/`)
      .then(({ data }) => setFile(data))
      .catch(err => toast.error(`There was an error loading the file: ${err}`))
      .finally(() => setLoading(null));
  }, [id, toast]);

  return (
    <Loader loaded={loading} type="screen">
      {files.length > 1 && <UploadFilesProgress files={files} current={id} />}
      {file && (
        <AssetEditForm
          loading={loading}
          setLoading={setLoading}
          onSubmit={() => {
            const updatedFileIndex = files.findIndex(
              ({ id: fid }) => parseInt(fid) === parseInt(id)
            );
            const isLastFile = updatedFileIndex === files.length - 1;

            const updatedHistory = isLastFile
              ? "/"
              : {
                  pathname: `/assets/${files[updatedFileIndex + 1].id}/edit`,
                  state: {
                    files: files.map(f =>
                      parseInt(f.id) === parseInt(id) ? file : f
                    )
                  }
                };

            history.push(updatedHistory);
          }}
          file={file}
          setFile={setFile}
        />
      )}
    </Loader>
  );
};

export default AssetEdit;
