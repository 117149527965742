import React from "react";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
import axios from "axios";

import {
  Button,
  Checkbox,
  Fieldset,
  Grid,
  GridCell,
  Heading,
  MediaWrap,
  Label,
  Loader,
  Paragraph,
  Radio,
  Section,
  TextInput,
  Wrapper
} from "@softiron/design-system";

import {
  IconTrashM as IconTrash,
  IconTickM as IconTick,
  IconCloseS as IconClose
} from "@softiron/icons-library";

import { useAssetsContext } from "context/AssetsContext";
import { useFilterContext } from "context/FilterContext";
import { useToast } from "context/ToastContext";
import { useToggle } from "hooks/useToggle";

import TagsInput from "components/TagsInput";
import FieldData from "components/AssetFieldData";

import DeleteAssetBox from "components/DeleteAssetBox";

import { LOADING_STATES, ASSET_TYPES } from "helpers/constants";
import { compareNodes as stringsAreEqual, lowercase } from "helpers/string";

const AssetEditForm = ({
  file: formData,
  setFile: setFormData,
  onSubmit,
  loading,
  setLoading
}) => {
  const { meta } = useFilterContext();
  const { end_uses: endUses = [], types: subTypes = [] } = meta;
  const { refetch } = useAssetsContext();
  const { toast } = useToast();
  const history = useHistory();

  const [boxOpen, toggleBox] = useToggle(false);

  const {
    id,
    url,
    type = "",
    uploaded_on,
    thumbnail,
    format,
    tags,
    end_uses = [],
    name = "",
    sub_type,
    meta: { height, width }
  } = formData;

  const checkBoxChangeHandler = ({ target: { name, id, checked } }) => {
    const value = id.slice(0, -1);
    setFormData({
      ...formData,
      [name]: checked
        ? [...end_uses, value]
        : [...end_uses.filter(e => e !== value)]
    });
  };

  const radioChangeHandler = ({ target: { name, id } }) => {
    const value = id.slice(0, -1);
    setFormData({ ...formData, [name]: value });
  };

  const inputChangeHandler = ({ target: { value, name } }) => {
    setFormData({ ...formData, [name]: value });
  };

  const setTags = tags => setFormData({ ...formData, tags });

  const saveAssetHandler = () => {
    setLoading(LOADING_STATES.LOADING);
    axios
      .put(`/assets/${id}/`, { name, sub_type, end_uses, tags })
      .then(() => {
        refetch();
        setLoading(LOADING_STATES.SUCCESS);
        toast.success("File updated successfully");
        if (onSubmit && typeof onSubmit === "function") onSubmit();
      })
      .catch(err => {
        toast.error("There was an error updating the file: " + err);
      });
  };

  return (
    <Loader loaded={loading} type="screen">
      <Section>
        <Wrapper>
          <Grid className="inset-L">
            <GridCell width={{ default: "1-4" }} push={{ default: "1-12" }}>
              {!stringsAreEqual(type, ASSET_TYPES.VIDEO) ? (
                <MediaWrap
                  ratio={
                    stringsAreEqual(type, ASSET_TYPES.IMAGE) ? "1x1" : "16x9"
                  }
                  fit="contain"
                  className="mb-M"
                >
                  <img src={thumbnail} alt={name} />
                </MediaWrap>
              ) : (
                <iframe
                  src={`https://player.vimeo.com/video/${url
                    .split("/")
                    .pop()}?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=179564`}
                  width="100%"
                  height="344"
                  title={name}
                  frameBorder="0"
                  allow="autoplay; fullscreen"
                  allowFullScreen
                ></iframe>
              )}
              <FieldData
                className="mb-M"
                field="Uploaded"
                data={new Date(uploaded_on).toLocaleDateString()}
              />
              <Grid className="mb-M">
                {height && width && (
                  <GridCell width={{ default: "1-2" }}>
                    <FieldData field="Dimensions" data={`${width}x${height}`} />
                  </GridCell>
                )}
                <GridCell width={{ default: "1-2" }}>
                  <FieldData field="Format" data={format} />
                </GridCell>
              </Grid>
            </GridCell>
            <GridCell width={{ default: "7-12" }} push={{ default: "1-12" }}>
              <Heading className="mb-L" level={2}>
                {type} details
              </Heading>
              <div className="mb-L">
                <Fieldset
                  legend={`${type} name *`}
                  className="mb-M color-dark-grey"
                >
                  <TextInput
                    id="name"
                    name="name"
                    value={name}
                    onChange={inputChangeHandler}
                  />
                </Fieldset>
              </div>
              <div className="mb-L">
                <Fieldset
                  legend={`${type} category *`}
                  className="mb-M color-dark-grey"
                >
                  Select one
                </Fieldset>
                <Grid gutters="XS">
                  {subTypes[type] &&
                    subTypes[type]
                      .filter(type => type !== ASSET_TYPES.NO_TYPE)
                      .map(label => (
                        <GridCell key={label} width={{ default: "1-3" }}>
                          <Radio
                            box
                            flat
                            className="text-center text-capital mb-S"
                            id={`${label}s`}
                            name="sub_type"
                            checked={stringsAreEqual(label, sub_type)}
                            onChange={radioChangeHandler}
                          >
                            <Label
                              htmlFor={`${label}s`}
                              className="block mb-none"
                            >
                              {label}
                            </Label>
                          </Radio>
                        </GridCell>
                      ))}
                </Grid>
              </div>
              <div className="mb-L">
                <Fieldset legend="End use *" className="mb-M color-dark-grey">
                  Where this {type.toLowerCase()} end up being used or seen?
                  Select multiple if required
                </Fieldset>
                <Grid gutters="XS">
                  {endUses.map(label => (
                    <GridCell key={label} width={{ default: "1-3" }}>
                      <Checkbox
                        box
                        flat
                        className="text-center mb-S"
                        id={`${label}s`}
                        name="end_uses"
                        checked={end_uses
                          .map(lowercase)
                          .includes(lowercase(label))}
                        onChange={checkBoxChangeHandler}
                      >
                        <Label htmlFor={`${label}s`} className="block mb-none">
                          {label}
                        </Label>
                      </Checkbox>
                    </GridCell>
                  ))}
                </Grid>
              </div>
              <div className="mb-L">
                <Fieldset legend="Tags *" className="mb-M color-dark-grey">
                  Add some descriptive tags for this {type.toLowerCase()} to
                  make it easier to find for Softies. We have added one to get
                  you started.
                </Fieldset>
                <TagsInput tags={tags} setTags={setTags} />
              </div>
              <Grid className="mb-L" justify="spread" align="middle">
                <GridCell width={{ default: "auto" }}>
                  <Paragraph className="color-dark-grey font-medium mb-none">
                    * Required information
                  </Paragraph>
                </GridCell>
                <GridCell width={{ default: "auto" }}>
                  {!boxOpen ? (
                    <Button flat color="red" onClick={toggleBox}>
                      Delete from library <IconTrash />
                    </Button>
                  ) : (
                    <Button flat color="grey" onClick={toggleBox}>
                      Cancel <IconClose />
                    </Button>
                  )}
                </GridCell>
              </Grid>
              {boxOpen ? (
                <DeleteAssetBox
                  asset={formData}
                  toggleBox={toggleBox}
                  onDelete={() => {
                    refetch();
                    history.push("/");
                  }}
                  handleLoading={setLoading}
                />
              ) : (
                <Grid justify="spread">
                  <GridCell width={{ default: "1-3" }}>
                    <Button
                      inverse
                      color="grey"
                      block
                      align="spread"
                      onClick={() => history.push("/")}
                    >
                      Cancel
                      <IconClose />
                    </Button>
                  </GridCell>
                  <GridCell width={{ default: "1-3" }}>
                    <Button
                      onBlur={() => setLoading(null)}
                      loaded={loading}
                      block
                      align="spread"
                      onClick={saveAssetHandler}
                    >
                      Save changes
                      <IconTick />
                    </Button>
                  </GridCell>
                </Grid>
              )}
            </GridCell>
          </Grid>
        </Wrapper>
      </Section>
    </Loader>
  );
};

AssetEditForm.propTypes = {
  file: PropTypes.object.isRequired,
  setFile: PropTypes.func.isRequired,
  loading: PropTypes.string,
  setLoading: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired
};

export default AssetEditForm;
