import React, { Fragment } from "react";
import { NavLink } from "react-router-dom";

import { Heading, Section, Wrapper } from "@softiron/design-system";

import Header from "components/Header";

const NotFound = () => {
  return (
    <Fragment>
      <Header>
        <Heading level={1}>404</Heading>
      </Header>
      <Section className="inset-vertical-L">
        <Wrapper>
          <p>D’oh.</p>
          <p>
            <NavLink to="/">Go back Home</NavLink>
          </p>
        </Wrapper>
      </Section>
    </Fragment>
  );
};

export default NotFound;
