import React from "react";
import { Link } from "react-router-dom";

import { SiteFooter, Paragraph, Grid, GridCell } from "@softiron/design-system";

const Footer = props => {
  const { appVersion } = props;

  return (
    <SiteFooter>
      <Grid guttersVertical justify="spread">
        <GridCell width={{ default: "auto" }}>
          <Paragraph className="stack-none" size="xxsmall">
            SoHo Marketing Tools v{appVersion}
          </Paragraph>
        </GridCell>
        <GridCell width={{ default: "auto" }}>
          <Paragraph className="stack-none" size="xxsmall">
            Copyright &copy; SoftIron Limited, All rights reserved.{" "}
            <Link to="/terms">Terms of use</Link>.
          </Paragraph>
        </GridCell>
      </Grid>
    </SiteFooter>
  );
};

export default Footer;
