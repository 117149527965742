import React from "react";
import { useHistory, useLocation } from "react-router-dom";
import { CopyToClipboard } from "react-copy-to-clipboard";

import { Grid, GridCell, Button } from "@softiron/design-system";

import {
  IconInfoS as IconInfo,
  IconLinkM as IconLink,
  IconDownloadM as IconDownload
} from "@softiron/icons-library";

import { useToast } from "context/ToastContext";

const AssetsTableOptions = ({ asset }) => {
  const { url, id } = asset;
  const { toast } = useToast();
  const history = useHistory();
  const { search } = useLocation();

  return (
    <Grid element="ul" align="middle" justify="right" gutters="XS">
      <GridCell width={{ default: "auto" }}>
        <Button
          size="small"
          hideText
          flat
          color="grey"
          onClick={() =>
            history.push({
              pathname: `/assets/${id}`,
              search
            })
          }
        >
          <IconInfo />
          Info
        </Button>
      </GridCell>
      <GridCell width={{ default: "auto" }}>
        <Button
          size="small"
          hideText
          download
          flat
          color="grey"
          target="_blank"
          rel="noopener noreferrer"
          href={url}
        >
          <IconDownload />
          Download
        </Button>
      </GridCell>
      <GridCell width={{ default: "auto" }}>
        <CopyToClipboard
          text={url}
          onCopy={() =>
            toast.success("URL successfully copied to clipboard", 2000)
          }
        >
          <Button size="small" inverse>
            Copy URL
            <IconLink />
          </Button>
        </CopyToClipboard>
      </GridCell>
    </Grid>
  );
};

export default AssetsTableOptions;
