import React from "react";
import "./index.css";

import { Grid, GridCell, Button } from "@softiron/design-system";

import {
  IconDocumentM as IconDocument,
  IconImageM as IconImage,
  IconVideoM as IconVideo,
  IconFilterM as IconFunnel,
  IconAddS as IconAdd,
  themeGrey
} from "@softiron/icons-library";

import TabItem from "components/TabItem";

import { ASSET_TYPES } from "helpers/constants";
import { compareNodes } from "helpers/string";

import { useFilterContext } from "context/FilterContext";

const TabBar = ({ displayFilter, setDisplayFilter }) => {
  const { filters, setFilters } = useFilterContext();
  const { type } = filters;

  const typeIsActive = value => type && compareNodes(value, type);

  const setType = type => setFilters({ type, sub_types: [] });

  return (
    <div className="tab--bar flex flex--center">
      <Grid justify="spread" align="middle" className="width-full">
        <GridCell width={{ default: "1-6" }}>
          <Button
            onClick={() => setDisplayFilter(!displayFilter)}
            inverse
            color="navy"
          >
            <IconFunnel />
            {displayFilter ? "Hide" : "Filter"}
          </Button>
        </GridCell>
        <GridCell
          width={{ default: "2-3" }}
          className="flex flex--middle flex--center"
        >
          <TabItem
            onClick={() => setType(undefined)}
            className={!type ? "is-active" : null}
          >
            All assets
          </TabItem>
          <TabItem
            onClick={() => setType(ASSET_TYPES.IMAGE)}
            className={typeIsActive(ASSET_TYPES.IMAGE) ? "is-active" : null}
          >
            <IconImage className="square-L" theme={themeGrey} /> Images
          </TabItem>
          <TabItem
            onClick={() => setType(ASSET_TYPES.DOCUMENT)}
            className={typeIsActive(ASSET_TYPES.DOCUMENT) ? "is-active" : null}
          >
            <IconDocument className="square-L" theme={themeGrey} /> Documents
          </TabItem>
          <TabItem
            onClick={() => setType(ASSET_TYPES.VIDEO)}
            className={typeIsActive(ASSET_TYPES.VIDEO) ? "is-active" : null}
          >
            <IconVideo className="square-L" theme={themeGrey} /> Videos
          </TabItem>
        </GridCell>
        <GridCell width={{ default: "1-6" }} align="right">
          <Button to="/upload">
            Upload
            <IconAdd />
          </Button>
        </GridCell>
      </Grid>
    </div>
  );
};

export default TabBar;
