import React from "react";
import PropTypes from "prop-types";
import axios from "axios";

import { useToast } from "context/ToastContext";

import { IconTrashXL as IconTrash, themeRed } from "@softiron/icons-library";

import { LOADING_STATES } from "helpers/constants";

import {
  Grid,
  GridCell,
  Button,
  Box,
  Paragraph
} from "@softiron/design-system";

const DeleteAssetBox = ({
  asset: { name, type, id },
  toggleBox,
  handleLoading,
  onDelete
}) => {
  const { toast } = useToast();

  const deleteHandler = id => {
    handleLoading(LOADING_STATES.LOADING);
    axios.delete(`/assets/${id}/`).then(data => {
      toast.info("File deleted!");
      handleLoading(LOADING_STATES.SUCCESS);
      onDelete();
    });
  };

  return (
    <Box className="inset-L stack-L">
      <div className="stack-M text-center">
        <IconTrash theme={themeRed} width={64} height={64} />
      </div>
      <Paragraph className="text-center color-navy text-medium text-bold stack-S">
        {`Are you sure you want to delete ${name} (${type})?`}
      </Paragraph>
      <Paragraph className="stack-L text-center color-dark-grey font-medium">
        This is a permanent action that cannot be undone.
      </Paragraph>
      <Grid justify="center">
        <GridCell width={{ default: "auto" }}>
          <Button inverse color="grey" onClick={toggleBox}>
            Keep {type}
          </Button>
        </GridCell>
        <GridCell width={{ default: "auto" }}>
          <Button color="red" onClick={() => deleteHandler(id)}>
            Delete {type}
          </Button>
        </GridCell>
      </Grid>
    </Box>
  );
};

DeleteAssetBox.propTypes = {
  asset: PropTypes.object.isRequired,
  toggleBox: PropTypes.func.isRequired
};

export default DeleteAssetBox;
