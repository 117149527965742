import React from "react";
import "./index.css";

const ToastsContainer = ({ children }) => {
  return (
    <div role="alert" aria-atomic="true" className="alerts--container">
      {children}
    </div>
  );
};

export default ToastsContainer;
