import React, { Fragment } from "react";

import TagItem from "components/TagItem";

import { useFilterContext } from "context/FilterContext";

const FilterTags = () => {
  const { meta, filters, setFilters } = useFilterContext();
  const { tags = [] } = meta;
  const { tags: selected = [] } = filters;

  const toggleTag = name => {
    const values = selected.includes(name)
      ? selected.filter(tag => tag !== name)
      : selected.concat(name);
    setFilters({ tags: values });
  };

  return (
    <Fragment>
      {tags.map(tag => (
        <TagItem
          active={selected.includes(tag)}
          onClick={toggleTag}
          key={tag}
          value={tag}
          className="stack-S"
        />
      ))}
    </Fragment>
  );
};

export default FilterTags;
