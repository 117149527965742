import React, { useState, useEffect } from "react";
import { useLocation, useHistory, useParams } from "react-router-dom";
import classnames from "classnames";
import qs from "query-string";

import {
  IconGridS as IconGrid,
  IconListM as IconList
} from "@softiron/icons-library";

import {
  Button,
  Label,
  Loader,
  Grid,
  GridCell,
  Paragraph,
  Select,
  Section,
  Wrapper
} from "@softiron/design-system";

import AssetsTableView from "components/AssetsTableView";
import AssetsGridView from "components/AssetsGridView";

import ModalAssetDetail from "components/ModalAssetDetail";
import AssetsFilter from "components/AssetsFilter";
import TabBar from "components/TabBar";
import Pagination from "components/Pagination";

import { useAssetsContext } from "context/AssetsContext";
import { useFilterContext } from "context/FilterContext";
import { useModalContext } from "context/ModalContext";

import { stringSort } from "helpers/utils";

import {
  filterByTags,
  filterByEndUses,
  filterBySearch,
  filterByDate,
  filterBySubTypes,
  filterByType
} from "helpers/filters";

import { PER_PAGE, ASSETS_VIEW } from "helpers/constants";

const Assets = () => {
  const { showModal, hideModal } = useModalContext();
  const { loading, assetsList = [] } = useAssetsContext();
  const { filters } = useFilterContext();
  const {
    type,
    tags: tagsFilter = [],
    sub_types: typesFilter = [],
    search: searchFilter,
    date: dateFilter = "",
    end_uses: endUsesFilter = []
  } = filters;

  const [view, setView] = useState(ASSETS_VIEW.LIST);
  const [displayFilter, setDisplayFilter] = useState(false);

  const history = useHistory();
  const { id } = useParams();
  const { search = "" } = useLocation();
  const { page = 1, sort = "uploaded_on:desc" } = qs.parse(search);

  useEffect(() => {
    const asset = assetsList.find(
      ({ id: aid }) => parseInt(id) === parseInt(aid)
    );
    if (asset)
      showModal(
        <ModalAssetDetail
          asset={asset}
          title="Asset detail"
          onClose={() => history.push({ pathname: "/", search })}
        />
      );
    else {
      history.push({
        pathname: "/",
        search:
          "?" +
          search
            .replace("?", "")
            .split("&")
            .filter(e => !e.includes("code=") && !e.includes("state="))
            .join("&")
      });
      hideModal();
    }
  }, [id, assetsList, history, showModal]);

  const paginate = page => {
    updateSorting({
      page,
      sort
    });
  };

  const handleSort = ({ target }) => {
    const { value } = target;
    updateSorting({
      page: 1,
      sort: value
    });
  };

  const updateSorting = data => {
    history.push({
      search: qs.stringify(
        {
          ...qs.parse(search, { arrayFormat: "comma" }),
          ...data
        },
        {
          encode: false,
          arrayFormat: "comma"
        }
      )
    });
  };

  // We filter using all filters
  const filtered = assetsList
    .filter(filterByType(type))
    .filter(filterByDate(dateFilter))
    .filter(filterBySearch(searchFilter))
    .filter(filterByTags(tagsFilter))
    .filter(filterByEndUses(endUsesFilter))
    .filter(filterBySubTypes(typesFilter));

  // We make sure the page is between 1 and the last page
  const currPage = Math.min(
    Math.max(page, 1),
    Math.ceil(filtered.length / PER_PAGE)
  );

  // We sort and paginate taking a subset of PER_PAGE elements
  const paginated = filtered
    .sort(stringSort(...sort.split(":")))
    .slice((currPage - 1) * PER_PAGE, currPage * PER_PAGE);

  return (
    <Loader isLoading={loading} type="screen">
      <TabBar
        displayFilter={displayFilter}
        setDisplayFilter={setDisplayFilter}
      />

      <AssetsFilter enabled={displayFilter} />

      <Section
        className={classnames("inset-vertical-L", { __pushed: displayFilter })}
      >
        <Wrapper>
          <Grid
            className="stack-M inset-horizontal-M"
            align="middle"
            justify="spread"
          >
            <GridCell width={{ default: "1-2" }}>
              {!!assetsList.length && (
                <Paragraph size="xsmall" className="stack-none">
                  {filtered.length} {type ? `${type.toLowerCase()}s` : "assets"}{" "}
                  in the library
                </Paragraph>
              )}
            </GridCell>
            <GridCell width={{ default: "1-2" }} className="text-right">
              <Grid element="ul" justify="right" gutters="none">
                <GridCell width={{ default: "auto" }}>
                  <Grid>
                    <GridCell width={{ default: "full", small: "1-4" }}>
                      <Label htmlFor="Sort" spaceTop="small">
                        Sort:
                      </Label>
                    </GridCell>
                    <GridCell width={{ default: "full", small: "3-4" }}>
                      <Select
                        className="stack-none"
                        id="sort"
                        name="sort"
                        value={sort}
                        onChange={handleSort}
                      >
                        <option value="uploaded_on:desc">New - old</option>
                        <option value="uploaded_on:asc">Old - new</option>
                        <option value="name:desc">Name desc</option>
                        <option value="name:asc">Name asc</option>
                      </Select>
                    </GridCell>
                  </Grid>
                </GridCell>
                <GridCell width={{ default: "1-12" }}>
                  <Button
                    flat
                    color={view === ASSETS_VIEW.LIST ? "navy" : "grey"}
                    hideText
                    onClick={() => setView(ASSETS_VIEW.LIST)}
                  >
                    <IconList />
                    List
                  </Button>
                </GridCell>
                <GridCell width={{ default: "1-12" }}>
                  <Button
                    flat
                    color={view === ASSETS_VIEW.GRID ? "navy" : "grey"}
                    hideText
                    onClick={() => setView(ASSETS_VIEW.GRID)}
                  >
                    <IconGrid />
                    Grid
                  </Button>
                </GridCell>
              </Grid>
            </GridCell>
          </Grid>
          {view === ASSETS_VIEW.LIST ? (
            <AssetsTableView assets={paginated} squashed={displayFilter} />
          ) : (
            <AssetsGridView assets={paginated} squashed={displayFilter} />
          )}
          <Pagination
            page={parseInt(currPage)}
            pages={Math.ceil(filtered.length / PER_PAGE)}
            paginate={paginate}
          />
        </Wrapper>
      </Section>
    </Loader>
  );
};

export default Assets;
