import React from "react";
import { useHistory, useLocation } from "react-router-dom";
import classnames from "classnames";
import { CopyToClipboard } from "react-copy-to-clipboard";

import { IconLinkM as IconLink } from "@softiron/icons-library";

import {
  Button,
  Box,
  Grid,
  GridCell,
  MediaWrap,
  Paragraph
} from "@softiron/design-system";

import { useToast } from "context/ToastContext";

import { localiseDateWithHyphen } from "helpers/string";

const GridCard = ({ asset }) => {
  const { url, name, uploaded_on, thumbnail, sub_type, id } = asset;

  const { toast } = useToast();
  const history = useHistory();
  const { search } = useLocation();

  const openDetailsModal = () =>
    history.push({
      pathname: `/assets/${id}`,
      search
    });

  return (
    <Box className="pa-none" cover>
      <MediaWrap fit="contain" className={classnames("bg-color-lighter-grey")}>
        <img src={thumbnail} alt={name} />
      </MediaWrap>
      <div className="pa-M">
        <Button
          cover
          size="large"
          color="navy"
          height="short"
          className="h4 mb-S"
          onClick={openDetailsModal}
        >
          {name.replace(/-/g, " ").replace(/_/g, " ")}
        </Button>
        <Grid justify="spread">
          <GridCell width={{ default: "auto" }}>
            <Paragraph size="xxsmall" className="color-dark-grey stack-none">
              {localiseDateWithHyphen(new Date(uploaded_on))}
            </Paragraph>
          </GridCell>
          <GridCell width={{ default: "auto" }}>
            <Paragraph
              size="xxsmall"
              className="color-dark-grey stack-none text-capital"
            >
              {sub_type}
            </Paragraph>
          </GridCell>
        </Grid>
      </div>
      <CopyToClipboard
        text={url}
        onCopy={() => toast.success("URL succefully copied to clipboard")}
      >
        <Button size="large" flat block>
          Copy URL
          <IconLink />
        </Button>
      </CopyToClipboard>
    </Box>
  );
};

export default GridCard;
