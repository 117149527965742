import React from "react";
import PropTypes from "prop-types";

const Header = ({ children, ...props }) => (
  <header className="header" {...props}>
    <div className="header__inner">{children}</div>
  </header>
);

Header.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ])
};

export default Header;
