import React from "react";

import { Checkbox, Heading, Grid, GridCell } from "@softiron/design-system";

import { useFilterContext } from "context/FilterContext";
import { useAssetsContext } from "context/AssetsContext";

import AssetFilterSection from "components/AssetsFilter/AssetFilterSection";

import { ASSET_TYPES } from "helpers/constants";
import { compareNodes } from "helpers/string";
import { filterByType } from "helpers/utils";

const removeUnknows = type => !compareNodes(type, ASSET_TYPES.NO_TYPE);

const buildType = (type, subType) => `${type}:${subType}`;

const FilterTypes = () => {
  const { assetsList = [] } = useAssetsContext();
  const { meta, filters, setFilters } = useFilterContext();
  const { types = {} } = meta;
  const { sub_types: selected = [], type } = filters;

  return Object.keys(types).map(
    t =>
      (!type || compareNodes(type, t)) && (
        <AssetFilterSection key={t}>
          <div className="section--header">
            <Grid align="middle" justify="spread">
              <GridCell width={{ default: "auto" }}>
                {!type ? (
                  <Checkbox
                    id={t}
                    label={`${t}s`}
                    name={t}
                    checked={selected.some(label => label.includes(t))}
                    onChange={({ target }) => {
                      const { checked } = target;
                      setFilters({
                        sub_types: checked
                          ? [
                              ...selected,
                              ...types[t]
                                .filter(removeUnknows)
                                .map(st => buildType(t, st))
                            ]
                          : selected.filter(val => !val.includes(t))
                      });
                    }}
                  />
                ) : (
                  <Heading level={5}>{t}s</Heading>
                )}
              </GridCell>
              <GridCell width={{ default: "auto" }}>
                {assetsList.filter(filterByType(t)).length || 0}
              </GridCell>
            </Grid>
          </div>
          {types[t].filter(removeUnknows).map(subType => (
            <div className="section--header--check" key={subType}>
              <Checkbox
                checked={selected.includes(buildType(t, subType))}
                id={buildType(t, subType)}
                label={subType}
                name={buildType(t, subType)}
                onChange={({ target }) => {
                  const { checked } = target;
                  const label = buildType(t, subType);
                  setFilters({
                    sub_types: checked
                      ? [...selected, label]
                      : selected.filter(val => !compareNodes(val, label))
                  });
                }}
              />
            </div>
          ))}
        </AssetFilterSection>
      )
  );
};

export default FilterTypes;
