/**
 * Sort function by date
 * @param {String, String} Strings in date format
 * @returns {Number} 0, 1, -1 for sorting
 */
export const dateSort = (a, b) =>
  new Date(b.uploaded_on) - new Date(a.uploaded_on);

export const stringSort = (field, order) => (a, b) => {
  var nameA = a[field].toLowerCase(); // ignore upper and lowercase
  var nameB = b[field].toLowerCase(); // ignore upper and lowercase
  if (nameA < nameB) {
    return order === "asc" ? -1 : 1;
  }
  if (nameA > nameB) {
    return order === "desc" ? -1 : 1;
  }

  return 0;
};

// Filters
export const assetIsType = (asset, type) =>
  asset.type.toLowerCase() === type.toLowerCase();

export const filterByType = type => asset => assetIsType(asset, type);
