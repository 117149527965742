import React, { useState } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";

import TagItem from "components/TagItem";

import "./index.css";

import { Box } from "@softiron/design-system";

const TagsInput = ({ tags = [], setTags }) => {
  const [input, setInput] = useState("");
  const [focus, setFocus] = useState(false);

  const handleRemoveTag = index => {
    setTags(tags.filter((tag, i) => i !== index));
  };

  const handleInputChange = ({ target: { value } }) => {
    setInput(value);
  };

  const handleKeyDown = ({ target, keyCode }) => {
    if (keyCode === 13) {
      const { value } = target;
      setTags([...tags, value]);
      setInput("");
    }

    if (tags.length && keyCode === 8 && !input.length) {
      setTags(tags.slice(0, tags.length - 1));
    }
  };

  return (
    <Box
      className={classnames(
        "tag__input bg-color-lighter-grey border-color-grey inset-XS",
        { focused: focus }
      )}
    >
      <ul className="flex flex--center">
        {tags.map((tag, i) => (
          <li key={i}>
            <TagItem value={tag} onClick={() => handleRemoveTag(i)} />
          </li>
        ))}
        <input
          type="text"
          className="tag__input"
          value={input}
          onFocus={() => setFocus(true)}
          onBlur={() => setFocus(false)}
          onChange={handleInputChange}
          onKeyDown={handleKeyDown}
        />
      </ul>
    </Box>
  );
};

TagsInput.propTypes = {
  assetTags: PropTypes.array
};

export default TagsInput;
